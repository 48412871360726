<template>
  <b-modal
    id="surgery-information-modal"
    hide-header
    hide-footer
    size="lg"
    centered
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span>Informações da cirurgia</span>
      </div>
      <span class="icon-box">
        <Close class="icon" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <PatientData :patient="patient" />
      <hr />

      <b-row>
        <b-col cols="12">
          <StatusSelect
            :status="surgeryInformation?.status"
            :statusTime="surgeryInformation?.status_time"
            :isLoadding="isLoadding"
            @change-status="changeStatus"
          />
        </b-col>
      </b-row>

      <ItemHeader title="Dados da cirurgia" class="mt-3">
        <template v-slot:content>
          <Procedure
            v-for="(procedure, index) in surgeryProcedures"
            :key="index"
            :procedure="procedure"
            :surgeryInformationId="surgeryInformation?.id"
            :manageMatMed="index === surgeryProcedures.length - 1"
          />

          <div class="information">
            <b-row class="mb-4">
              <b-col cols="12">
                <p class="more-title">Mais informações</p>
              </b-col>
            </b-row>

            <b-row
              class="mb-3"
              v-for="(information, index) in moreInformation"
              :key="index"
            >
              <b-col cols="12">
                <p>
                  {{ information.title }} <span>{{ information.data }}</span>
                </p>
              </b-col>
            </b-row>
          </div>
        </template>
      </ItemHeader>

      <SurgeryInformation
        :showSaveButton="false"
        v-bind="$props"
        :showSurgeryData="false"
        @updateSurgeryProcedures="setSurgeryProcedures"
        @get-surgery-information="surgeryInformation = $event"
      />
    </div>

    <LogsModal :logId="surgeryInformation?.id" logType="surgery-information" />
  </b-modal>
</template>

<script>
import {
  timeDurationsFormated,
  periodTimeFormated,
  dateFormated
} from '@/utils/dateHelper'
export default {
  name: 'SurgeryInformationModal',
  props: {
    appointmentId: {
      type: String,
      required: true
    },
    patientId: {
      type: String,
      required: true
    },
    appointment: {
      type: Object,
      required: true
    }
  },
  components: {
    ItemHeader: () =>
      import('@/components/SurgeryCenter/SurgeryInformation/ItemHeader'),
    PatientData: () => import('@/components/General/PatientData'),
    StatusSelect: () => import('../StatusSelect'),
    Procedure: () => import('../Procedure'),
    SurgeryInformation: () =>
      import('@/components/AppointmentModal/SurgeryInformation'),
    Close: () => import('@/assets/icons/close.svg'),
    LogsModal: () => import('@/components/Logs/LogsModal')
  },
  data: () => ({
    surgeryInformation: null,
    patient: {},
    isLoadding: false,
    surgeryProcedures: []
  }),
  computed: {
    moreInformation() {
      return [
        { title: 'Sala:', data: this.appointment?.room?.name || '-' },
        {
          title: 'Equipamento:',
          data: this.appointment?.equipment?.name || '-'
        },
        { title: 'Data:', data: dateFormated(this.appointment.start_datetime) },
        {
          title: 'Horário:',
          data: periodTimeFormated(
            this.appointment.start_datetime,
            this.appointment.end_datetime
          )
        },
        {
          title: 'Duração prevista:',
          data: timeDurationsFormated(
            this.appointment.start_datetime,
            this.appointment.end_datetime
          )
        }
      ]
    }
  },
  methods: {
    setSurgeryProcedures(procedures) {
      this.surgeryProcedures = procedures
    },
    async updateSurgeryInformation() {
      this.isLoadding = true
      try {
        await this.api.updateSurgeryInformation(
          this.surgeryInformation.id,
          this.surgeryInformation
        )
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.isLoadding = false
      }
    },
    async getPerson() {
      const isLoadding = this.$loading.show()
      try {
        const { data } = await this.api.getPerson(this.patientId)
        this.patient = data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoadding.hide()
      }
    },
    async getSurgeryInformationProcedures() {
      this.isLoading = true
      try {
        const { data } =
          await this.api.surgeryInformationProceduresListWithRelations(
            this.surgeryInformation.id
          )
        this.surgeryProcedures = data
      } catch (error) {
        this.$toast.error('Erro ao carregar informações da cirurgia')
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.$bvModal.hide('surgery-information-modal')
      this.surgeryInformation = null
      this.surgeryProcedures = []
      this.patient = {}
      this.$emit('close-modal')
    },
    async changeStatus(status) {
      this.surgeryInformation.status = status
      this.surgeryInformation.status_time = this.moment().format(
        'YYYY-MM-DD HH:mm:ss'
      )
      await this.updateSurgeryInformation()
      this.$emit('update-surgery-status', {
        id: this.surgeryInformation?.id,
        status: this.surgeryInformation?.status
      })
    }
  },
  watch: {
    patientId: {
      async handler(value) {
        if (!value) return
        await this.getPerson()
      },
      immediate: true
    },
    'surgeryInformation.id': {
      async handler(value) {
        if (!value) return
        await this.getSurgeryInformationProcedures()
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#surgery-information-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;
  }

  .information {
    p {
      color: var(--type-active);
      font-size: min(1.2vw, 16px);
      font-weight: 600;

      &.more-title {
        font-size: min(2.6vw, 18px);
        color: var(--neutral-700);
      }
    }

    span {
      color: var(--type-placeholder) !important;
    }
  }
}
</style>
